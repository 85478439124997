import * as React from 'react';
import { Container } from '@chakra-ui/react';
import type { HeadFC } from 'gatsby';
import { SEO } from '../components/Seo';
import { PageLayout } from '../components/PageLayout';
import { CONTAINER_SIZE } from '../core/constants';
import { SupportEmailLink } from '../components/SupportEmailLink';
import { styles } from '../page-styles/legal-page.styles';
import './index.css';

const LegalNoticePage = () => (
  <PageLayout>
    <Container className={styles.legalPage} maxW={CONTAINER_SIZE}>
      <h2>Legal Notice</h2>
      <p>Dedekarginoglu & Kaltsikis GbR (doing business as mangoarray).</p>

      <p>
        Boxhagener Str. 51
        <br />
        10245 Berlin
      </p>

      <p>Partners: Ali Can Dedekarginoglu and Georgios Panagiotis Kaltsikis.</p>

      <p>
        Email: <SupportEmailLink />
      </p>
    </Container>
  </PageLayout>
);

export default LegalNoticePage;

export const Head: HeadFC = () => (
  <SEO
    title="Legal Notice - instatab"
    description="Legal Notice of instatab, the number one app for splitting and managing expenses."
    ogDescription="Legal Notice of instatab, the number one app for splitting and managing expenses."
  />
);
